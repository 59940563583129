import { basePath } from '@/utils/stores';

export const siteSettings = {
  name: 'Goodform',
  description: '',
  author: {
    name: 'Goodform GmbH',
    websiteUrl: 'https://www.goodform.de',
  },
  logo: {
    url: basePath + '/assets/images/logo.svg',
    alt: 'Goodform',
    href: '/',
    width: 200,
    height: 30,
  },
};
