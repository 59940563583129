import { CmsPageQuery } from '.gql/graphql';
import { CategoryListQuery } from '@/graphql/category/CategoryList.gql';
import { CmsBlockFragment } from '@/graphql/cms/Blocks.gql';
import { StoreConfigFragment } from '@/graphql/store/StoreConfig.gql';
import { storeEmailAddressesConfigQuery } from '@/graphql/store/StoreEmailAdressesConfig.gql';

import { createContext, ReactNode } from 'react';

export type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export interface AppContextProps {
  storeConfig: Required<RequiredNotNull<StoreConfigFragment>> & {
    emails?: NonNullable<storeEmailAddressesConfigQuery['StoreEmailAddressesConfig']> | null;
  };
  categories?: NonNullable<CategoryListQuery['categories']>['items'];
  categoriesHash?: string;
  headerPromotionBlock?: CmsBlockFragment | null;
  clientErrorPage?: CmsPageQuery['cmsPage'] | null;
  unbxdProductsCount: number;
}

const defaultProps: AppContextProps = {
  storeConfig: {
    __typename: 'StoreConfig',
    store_code: '',
    store_name: '',
    website_name: '',
    default_display_currency_code: '',
    cms_home_page: '',
    default_title: '',
    product_url_suffix: '',
    category_url_suffix: '',
    root_category_uid: '',
    default_description: '',
    secure_base_url: '',
  },
  unbxdProductsCount: 45000,
};

export const AppContext = createContext<AppContextProps>({
  ...defaultProps,
});

export function AppProvider(props: { props: AppContextProps | undefined; children: ReactNode }) {
  if (!props.props) {
    props = {
      props: {
        ...defaultProps,
      },
      children: props.children,
    };
  }
  return <AppContext.Provider value={{ ...props.props! }}>{props.children}</AppContext.Provider>;
}
