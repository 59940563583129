import { useStore } from '@/hooks/store/useStore';
import CookieBar from '@components/common/cookie-bar';
import Footer from '@components/layout/footer/footer';
import Header from '@components/layout/header/header';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { ComponentProps } from 'react';

interface LayoutProps extends React.PropsWithChildren {
  headerProps?: ComponentProps<typeof Header>;
  footer?: boolean;
}

export default function Layout(props: LayoutProps) {
  const store = useStore();
  const footer = props.footer ?? true;

  return (
    <div className="flex min-h-screen flex-col">
      <NextSeo
        additionalMetaTags={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
        ]}
        description={store.default_description}
        canonical={store.secure_base_url}
      />
      <Head>
        <title>{store.default_title}</title>
      </Head>
      <CookieBar />
      <Header {...props.headerProps} />
      <main
        className="relative flex-grow"
        style={{
          minHeight: '-webkit-fill-available',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {props.children}
      </main>
      {footer && <Footer />}
      {process.env.NEXT_PUBLIC_ETRUSTED_TS_ID && (
        <script
          async
          data-desktop-y-offset="-20"
          data-mobile-y-offset="0"
          data-desktop-disable-reviews="false"
          data-desktop-enable-custom="false"
          data-desktop-position="right"
          data-desktop-custom-width="156"
          data-desktop-enable-fadeout="false"
          data-disable-mobile="false"
          data-disable-trustbadge="false"
          data-mobile-custom-width="156"
          data-mobile-disable-reviews="false"
          data-mobile-enable-custom="false"
          data-mobile-position="left"
          data-mobile-enable-topbar="false"
          data-mobile-enable-fadeout="true"
          data-color-scheme="light"
          src={`//widgets.trustedshops.com/js/${process.env.NEXT_PUBLIC_ETRUSTED_TS_ID}.js`}
        ></script>
      )}
    </div>
  );
}
